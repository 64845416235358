.section {
   display: flex;
   flex-flow: column;
   margin-top: 0.8rem;

   .label {
      font-weight: 600;
      font-size: 0.9rem;
      margin-bottom: 4px;
   }
}
