.toggleSelector {
   overflow: auto;

   > [aria-hidden] {
      display: none;
   }
}

.wrapper {
   display: flex;
}

.button {
   border: none;
   background: none;
   padding: 0.5rem;

   display: flex;
   flex-flow: column;
   align-items: center;
   border-radius: 3px;

   cursor: pointer;

   &.active {
      background: rgb(238, 238, 238);
   }

   &:focus {
      outline: none;
   }
}
