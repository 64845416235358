@import "./globals.module";

.toggleWithIcon {
   color: $accent;
   font-size: 0.8rem;
   min-width: 3.5rem;

   svg,
   img {
      height: 1.5rem;
      fill: $accent;
   }
}

.size {
   min-width: 3.5rem;
   min-height: 3.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.attribute {
   display: flex;
   flex-direction: row;
   align-items: center;
   .attributeImage {
      margin-left: 1rem;
      margin-right: 0.5rem;
      width: 24px;
      height: 24px;
   }
}
