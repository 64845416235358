@import "./globals.module";

.app {
   padding: 1rem;
   .header {
      margin-bottom: 1rem;
   }
}



