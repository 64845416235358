.settings {
   display: flex;
   flex-direction: column;
   padding-top: 1rem;
   padding-bottom: 1rem;
}

.about {
   display: flex;
   flex-flow: column;

   .appName { 
      font-weight: 600;
   }

   h5 {
      margin: 0;
      margin-top: 8px;

      &:first-child {
         margin-top: 0;
      }
   }
}

.card {
   display: flex;

   margin-left: 5px;
   margin-right: 5px;
   margin-bottom: 20px;

   background-color: #fff;
   box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
   padding: 1rem;

   .columns {
      display: flex;
      flex-flow: row;

      .icon {
         font-size: 32px;
      }
      .content {
         margin-left: 1rem;
         .title {
            margin: 0;
            margin-bottom: 1rem;
            font-weight: 600;
         }
      }
   }
}
