@import "./globals.module";

.header {
    display: flex;
    flex-flow: row;
    align-items: center;
   //  background-color: #307abb;
    padding: 0.1rem;

    .title {
        margin-left: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $accent
    }
    .settings {
       margin-left: auto;
    }

    
}

.settingsPanel {
   background-color: #faf9f8;

 }
