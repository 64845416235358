$background: #f5f5f5;
$foreground: black;

$panelBackground: #e8e8e8;
$accentedForeground: $panelBackground;

$accent: #464676;
$accent-light: #6264a7;
$shadedBg: #ddd;// lighten($accent, 38.3);

$red: #ef4343;
$green: #138d52;

