@import "./globals.module";

.empty {
   color: #8a8a8a;
}

.shimmer {
   margin-top: 0.5rem;
}

.resourceItem {
   display: flex;
   flex-flow: row;
   align-items: center;
   margin-bottom: 0.5rem;
   padding: 0.3rem;

   &:not(.disabled):hover {
      background: rgb(238, 238, 238);
   }

   .icon {
      background-color: $accent;
      border-radius: 50%;
      height: 1.2rem;
      width: 1.2rem;
      padding: 0.6rem;
      svg {
         fill: white;
      }
   }

   &.disabled {
      .icon {
         background-color: #dfdfdf;
      }
   }

   .info {
      display: flex;
      flex-flow: column;
      margin-left: 0.5rem;
      font-size: 1rem;
      color: #555555;
   }

   &.disabled {
      .info {
         color: #dfdfdf;
      }
   }

   .roomSize {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #a5a2a2;

      .sizeIcon {
         font-size: 0.7rem;
      }
   }

   &.disabled {
      .roomSize {
         color: #dfdfdf;
      }
   }

}
